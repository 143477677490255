import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { preparePickupOffersSelected } from './questionary'

export const serviceMap = ({ service, isShort = false }) => {
  let resolve = {
    title: _.get(service, 'name', ''),
    name: _.get(service, 'name', ''),
    price: service.price != null ? Number(service.price) : null
  }

  if (isShort) return resolve

  resolve = {
    ...resolve,
    id: service?.id ?? service?._id,
    code: _.get(service, 'code', ''),
    description: _.get(service, 'description', ''),
    isSelect: _.get(service, 'enabled_by_default', false),
    isRemoteCalc: _.get(service, 'remote_calc', false),
    type: 'switch'
  }

  return resolve
}

export const serviceGroupMap = ({ group }) => ({
  groupName: _.get(group, 'group.name', ''),
  groupInfo: _.get(group, 'group.info', ''),
  groupId: group?.group?.id ?? group.group?._id ?? uuid(),
  services: _.map(group?.services, service => serviceMap({ service }))
})

export const offerShortMap = ({ offer }) => {
  const bankId = _.get(offer, 'bank.id') || _.get(offer, 'bank._id')
  const bank = {
    id: bankId,
    name: _.get(offer, 'bank.name'),
    systemName: _.get(offer, 'bank.system_name', '')
  }

  const resolve = {
    bank,
    isProcessGetOffers: true,
    offers: []
  }
  resolve.id = offer?._id ?? offer?.id ?? uuid()
  return resolve
}

export const offerMap = ({ offer, isFinish = false }) => {
  const bankId = _.get(offer, 'bank._id', '') || _.get(offer, 'bank.id', '')
  const bank = {
    id: bankId,
    name: _.get(offer, 'bank.name', ''),
    systemName: _.get(offer, 'bank.system_name', '')
  }

  return {
    bank,
    id: bankId,
    isProcessGetOffers: !isFinish ? !offer?.complete : false,
    isMissOffers: !isFinish ? offer?.complete && _.isEmpty(offer?.products) : _.isEmpty(offer?.products),
    offers: _.map(offer?.products, product => ({
      bank,
      id: product?._id ?? product?.id ?? uuid(),
      productName: _.get(product, 'name', ''),
      initialPayment: Number(_.get(product, 'result.initial_payment', 0)),
      term: Number(_.get(product, 'result.term', 0)),
      isSelect: false,
      services: _.map(product?.result?.services, service => serviceMap({ service })),
      insurance: product?.result?.insurance ? {
        name: _.get(product, 'result.insurance.product_name', ''),
        price: product?.result?.insurance.amount ? Number(product?.result?.insurance.amount) : 0
      } : null,
      monthlyPayment: Number(_.get(product, 'result.monthly_payment', 0)),
      amount: Number(_.get(product, 'result.amount', 0)),
      discount: Number((Number(_.get(product, 'result.discount', 0)) * 100).toFixed(2)),
      paymentsSum: Number(_.get(product, 'result.payments_sum', 0)),
      interestsSum: Number(_.get(product, 'result.interests_sum', 0)),
      tax: Number(_.get(product, 'result.tax', 0)),
      tipsLoaded: Object.prototype.hasOwnProperty.call(_.get(product, 'result'), 'tips') || Object.prototype.hasOwnProperty.call(_.get(product, 'result'), 'tips_error'),
      tipsSum: Number(_.get(product, 'result.tips', 0)),
      tipsError: _.get(product, 'result.tips_error', ''),
      isCard: _.get(product, 'result.is_card', false),
      errors: _.map(_.get(product, 'result.errors', []), err => (_.isString(err) ? err : JSON.stringify(err)))
    }))
  }
}

export const categoryMap = ({ category }) => ({
  id: category?._id ?? category?.id,
  name: _.get(category, 'name', '')
})

export const sentryPreparePickupOffers = offers => _.map(offers, offer => {
  const bank = { ...offer?.bank }
  const resolve = {
    ...offer,
    bankName: bank?.name,
    bankId: bank?.id
  }
  _.unset(resolve, 'bank')
  if (!_.isEmpty(offer?.offers)) {
    resolve.offers = _.map(offer?.offers, of => {
      const result = {
        ...of,
        bankName: bank?.name,
        bankId: bank?.id
      }
      _.unset(result, 'bank')
      return result
    })
  }
  return resolve
})

export const sentryGetUnselectedPickupOffers = (offers = []) => {
  const products = _.reduce(offers, (s, r) => [...r.offers], [])
  return _.flow(
    l => _.reduce(l, (s, offer) => [
      ...s,
      ...(_.findIndex(preparePickupOffersSelected(offers), product => product.prefer_product_id === offer.id) === -1 ?
        [offer] : [])
    ], []),
    l => sentryPreparePickupOffers(l)
  )(products)
}

export const sentryGetMissProductsPickupOffers = offers => _.flow(
  l => _.filter(l, offer => _.isEmpty(offer?.offers) && offer.isMissOffers),
  l => _.map(l, offer => {
    _.unset(offer, 'offers')
    return offer
  }),
  l => sentryPreparePickupOffers(l)
)(offers)
