import _ from 'lodash'

const mapTemplate = ({ template }) => ({
  id: template?.id ?? template?._id,
  name: _.get(template, 'name', ''),
  label: _.get(template, 'label', ''),
  isRequired: _.get(template, 'required', true),
  kind: _.get(template, 'kind', ''),
  defaultValue: _.get(template, 'default_value', ''),
  options: _.map(_.get(template, 'options', []), option => ({
    label: _.get(option, 'label', ''),
    value: _.get(option, 'value', '')
  }))
})

export const bankMap = ({ bank, isShort = true }) => {
  const resolve = {
    id: bank?.id ?? bank?._id,
    name: _.get(bank, 'name', '')
  }
  if (!isShort) {
    resolve.templates = _.map(bank?.templates ?? bank.template, template => mapTemplate({ template }))
    resolve.userTemplates = _.map(bank?.user_templates, template => mapTemplate({ template }))
  }

  return resolve
}
