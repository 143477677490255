import _ from 'lodash'
import { moment, DATE_FORMATS, DATE_FORMAT } from 'uikit'
import { notNull } from 'providers/helpers/null'
import { addressMap, addressMapApi } from 'providers/helpers/address'
import deepMerge from 'deepmerge'

export const pointMap = ({ point, isShort = true, map = {} }) => {
  let resolve = {
    id: point._id ?? point.id,
    name: _.get(point, 'name'),
    isProcessLock: false
  }
  if (!isShort) {
    resolve = {
      ...resolve,
      isActive: point?.enabled ?? point?.active ?? true,
      lockReason: _.get(point, 'lock_reason', ''),
      isOnline: _.get(point, 'online', false),
      address: point.address ? addressMap({ address: point.address }) : null,
      partnerCode: _.get(point, 'estore_code', ''),
      partnerOrganization: notNull(point.partner_organization, partner => ({
        id: partner._id ?? partner.id,
        name: _.get(partner, 'name', '')
      })),
      partnerBankMix: _.get(point, 'partner_organization.bank_mix_id', ''),
      isInitialPaymentCorrection: _.get(point, 'initial_payment_correction_by_discount', false),
      isRoundCents: _.get(point, 'round_cents_in_calculator', false),
      isFreezeInitialPayment: _.get(point, 'freeze_dp_in_calculator', false),
      bankMixID: _.get(point, 'bank_mix_id', ''),
      macroRegion: notNull(point.macroregion, macroRegion => ({
        id: macroRegion._id ?? macroRegion.id,
        name: _.get(macroRegion, 'name', ''),
        regionCodes: _.get(macroRegion, 'region_codes', [])
      })),
      retail: notNull(point.retail, retail => ({
        id: retail._id ?? retail.id,
        name: _.get(retail, 'name', '')
      })),
      segment: notNull(point.point_segment, segment => ({
        id: segment._id ?? segment.id,
        name: _.get(segment, 'name', '')
      })),
      partnerBank: notNull(point.main_partner, bank => ({
        id: bank._id ?? bank.id,
        name: _.get(bank, 'name', '')
      })),
      managers: _.map(_.get(point, 'point_managers', []), manager => ({
        id: manager?.manager?._id ?? manager?.manager?.id ?? '',
        name: manager?.manager?.fullname ?? manager?.manager?.email ?? '',
        startDate: manager.started_at ? moment(manager.started_at, DATE_FORMATS).toDate() : null
      })),
      isSendSalesReport: _.get(point, 'send_sales_report', true),
      salesReportRecipients: _.get(point, 'sales_report_recipients', []),
      callCenter: notNull(point.call_center, callCenter => ({
        id: callCenter._id ?? callCenter.id,
        name: _.get(callCenter, 'name', '')
      })),
      callCenterAgent: notNull(point.call_center_default_owner, agent => ({
        id: agent._id ?? agent.id,
        name: agent.fullname ?? agent.login ?? ''
      })),
      isCallCenterScansRequired: _.get(point, 'call_center_scans_required', false),
      isCallCenterWidgetSendToDefaultOwner: _.get(point, 'call_center_widget_send_to_default_owner', false),
      services: _.map(_.get(point, 'services', []), service => ({
        id: service._id ?? service.id,
        name: _.get(service, 'name', '')
      })),
      agentCommissionGroups: _.map(_.get(point, 'agent_commission_groups', []), pointACGroup => ({
        agentCommissionGroup: notNull(pointACGroup.agent_commission_group, acGroup => ({
          id: _.get(acGroup, 'id', ''),
          name: _.get(acGroup, 'name', '')
        })),
        startsOn: pointACGroup.starts_on ? moment(moment(pointACGroup.starts_on).toDate() - (moment(pointACGroup.starts_on).toDate().getTimezoneOffset() * 60000)).startOf('month') : null
      })),
      comment: _.get(point, 'comment', '')
    }
  }

  if (!_.isEmpty(map)) {
    resolve = deepMerge.all([{}, resolve, map])
  }
  return resolve
}

export const shortPointMap = ({ point }) => ({
  id: point._id ?? point.id,
  name: _.get(point, 'name'),
  address: _.get(point, 'address'),
  isActive: point.enabled ?? point?.active ?? true,
  lockReason: _.get(point, 'lock_reason', '')
})

export const pointMapApi = ({ point }) => {
  const resolve = ({
    name: point.name,
    estore_code: point.partnerCode !== '' ? point.partnerCode : null,
    partner_organization_id: point.partnerOrganization?.id ?? point.partnerOrganization,
    address: addressMapApi({ address: point.address }),
    online: point.isOnline,
    initial_payment_correction_by_discount: point.isInitialPaymentCorrection,
    round_cents_in_calculator: point.isRoundCents,
    freeze_dp_in_calculator: point.isFreezeInitialPayment,
    macroregion_id: point.macroRegion?.id ?? point.macroRegion,
    retail_id: point.retail?.id ?? point.retail,
    point_segment_id: point.segment?.id ?? point.segment,
    main_partner_id: point.partnerBank?.id ?? point.partnerBank,
    point_managers: _.map(point.managers, manager => ({
      manager_id: manager.id,
      started_at: manager.startDate ? moment(manager.startDate, DATE_FORMATS).format(DATE_FORMAT) : null
    })),
    send_sales_report: point.isSendSalesReport,
    call_center_id: point.callCenter?.id ?? point.callCenter,
    call_center_default_owner_id: point.callCenterAgent?.id ?? point.callCenterAgent,
    call_center_scans_required: point.isCallCenterScansRequired,
    call_center_widget_send_to_default_owner: point.isCallCenterWidgetSendToDefaultOwner,
    service_ids: _.map(point.services, p => p?.key ?? p?.id ?? p)
  })
  if (point.isSendSalesReport) {
    resolve.sales_report_recipients = point.salesReportRecipients
  }
  if (point.singleAccount) resolve.bank_account_id = point.singleAccount

  if (point.lockReason !== '') resolve.lock_reason = point.lockReason
  return resolve
}

export const segmentMap = ({ segment }) => ({
  id: segment?._id ?? segment?.id,
  name: _.get(segment, 'name', '')
})

export const pointSalesMap = ({ sales }) => ({
  bankPieces: _.map(_.get(sales, 'bank_pieces', []), bankPiece => ({
    completedPiece: _.get(bankPiece, 'completed_piece', 0),
    amount: _.get(bankPiece, 'amount', 0),
    bank: _.get(bankPiece, 'bank', '')
  })),
  insurancePieces: _.map(_.get(sales, 'insurance_pieces', []), insurancePiece => ({
    id: _.get(insurancePiece, '_id.bank_id', ''),
    total: _.get(insurancePiece, 'total', 0),
    count: _.get(insurancePiece, 'count', 0),
    bankInsurancePiece: _.get(insurancePiece, 'bank_insurance_piece', 0.0),
    externalInsurancePiece: _.get(insurancePiece, 'external_insurance_piece', 0.0),
    withoutInsurancePiece: _.get(insurancePiece, 'without_insurance_piece', 0.0),
    bankInsuranceAmount: _.get(insurancePiece, 'bank_insurance_amount', 0),
    externalInsuranceAmount: _.get(insurancePiece, 'external_insurance_amount', 0),
    withoutInsuranceAmount: _.get(insurancePiece, 'without_insurance_amount', 0),
    bank: _.get(insurancePiece, 'bank', '')
  })),
  smsPieces: _.map(_.get(sales, 'sms_pieces', []), smsPiece => ({
    id: _.get(smsPiece, '_id.bank_id', ''),
    total: _.get(smsPiece, 'total', 0),
    count: _.get(smsPiece, 'count', 0),
    bankSmsPiece: _.get(smsPiece, 'bank_sms_piece', 0.0),
    externalSmsPiece: _.get(smsPiece, 'external_sms_piece', 0.0),
    withoutSmsPiece: _.get(smsPiece, 'without_sms_piece', 0.0),
    bankSmsAmount: _.get(smsPiece, 'bank_sms_amount', 0),
    externalSmsAmount: _.get(smsPiece, 'external_sms_amount', 0),
    withoutSmsAmount: _.get(smsPiece, 'without_sms_amount', 0),
    bank: _.get(smsPiece, 'bank', '')
  })),
  servicesPieces: _.map(_.get(sales, 'services_pieces', []), servicePiece => ({
    name: _.get(servicePiece, 'name', ''),
    banks: _.map(_.get(servicePiece, 'banks', []), bank => ({
      name: _.get(bank, 'name', ''),
      piece: _.get(bank, 'piece', 0.0),
      total: _.get(bank, 'total', 0),
      bankTotal: _.get(bank, 'bank_total', 0),
      amountMin: _.get(bank, 'amount_min', 0),
      amountMax: _.get(bank, 'amount_max', 0)
    }))
  }))
})

export const pointCodeHistoryMap = ({ history }) => ({
  id: history.id ?? history._id,
  author: notNull(history?.author, mod => ({
    id: mod.id ?? mod._id,
    login: _.get(mod, 'login', ''),
    fullName: _.get(mod, 'fullname', ''),
    email: _.get(mod, 'email', ''),
    isAccessGranted: _.get(mod, 'access_granted', false),
    lastActivityAt: mod.last_activity_at ? moment(mod.last_activity_at, DATE_FORMATS).toDate() : null
  })),
  modifiedAt: history.modified_at ? moment(history.modified_at, DATE_FORMATS).toDate() : null,
  modifications: _.get(history, 'modifications', [])
})

export const pointACGroupMapApi = ({ acGroups }) => _.map(acGroups, acGroup => ({
  agent_commission_group_id: acGroup.agentCommissionGroup.id,
  starts_on: acGroup.startsOn
}))

export const pointSettingsMap = (settings) => (
  _.map(settings, bankSetting => ({
    bank: {
      id: _.get(bankSetting, 'bank._id', ''),
      name: _.get(bankSetting, 'bank.name', '')
    },
    isDirectFinancing: _.get(bankSetting, 'direct_financing', false)
  }))
)

export const pointSettingsMapApi = ({ settings }) => (
  _.map(settings, bankSetting => ({
    bank_id: _.get(bankSetting, 'bank.id', false),
    direct_financing: _.get(bankSetting, 'isDirectFinancing', false)
  }))
)

export const pointAccreditationMap = (accreditation) => (
  _.map(accreditation, bankAccreditation => ({
    bank: {
      id: _.get(bankAccreditation, 'bank._id', ''),
      name: _.get(bankAccreditation, 'bank.name', '')
    },
    status: _.get(bankAccreditation, 'status', ''),
    status_human: _.get(bankAccreditation, 'status_human', ''),
    error_details: _.get(bankAccreditation, 'error_details', ''),
    can_send: _.get(bankAccreditation, 'can_send', false)
  }))
)
