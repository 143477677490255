import _ from 'lodash'
import { moment, DATE_FORMATS } from 'uikit'

export const refundMap = (refund) => ({
  id: refund.id,
  internalRejectReason: _.get(refund, 'internal_reject_reason', ''),
  instructions: _.get(refund, 'instructions', ''),
  packageID: _.get(refund, 'poscans_package_id', ''),
  documents: _.map(_.get(refund, 'documents', []), doc => ({
    label: _.get(doc, 'label', ''),
    url: _.get(doc, 'url', '')
  })),
  scans: _.flow(
    l => _.map(l, bundle => [..._.map(bundle?.scans, scan => ({
      id: scan?.id ?? scan?._id,
      commonCode: _.get(scan, 'common_code', ''),
      name: _.get(scan, 'name', ''),
      fileName: scan?.url ? scan?.url.split('/').pop() : '',
      url: _.get(scan, 'url', ''),
      updatedAt: scan?.updated_at ? moment(scan?.updated_at, DATE_FORMATS).toDate() : null,
      canUpload: _.get(bundle, 'can_upload', false)
    }))]),
    l => _.flatten(l)
  )(_.get(refund, 'bundles', [])),
  state: _.get(refund, 'state', ''),
  adminUrl: _.get(refund, 'pl_admin_url', '')
})
