import _ from 'lodash'

export const PHOTO = 'PHOTO' // Фото
export const BORROWER_PHOTO = 'BORROWER_PHOTO' // Фотография клиента
export const CC_PASSPORT23 = 'CC_PASSPORT23' // Скан паспорта: 2 и 3 страница(разворот)
export const CC_PASSPORTREG = 'CC_PASSPORTREG' // Скан паспорта: страница с регистрацией
export const CC_PASSPORT19 = 'CC_PASSPORT19' // Скан паспорта: 19 страница
export const PD_AGREEMENT = 'PD_AGREEMENT' // Соглашение на обработку персональных данных

export const scanMap = ({ scan }) => ({
  id: _.get(scan, 'id', '') || _.get(scan, 'Id', '') ||
    _.get(scan, 'poscan_id', ''),
  bundleID: _.get(scan, 'Bundle.Id', ''),
  name: _.get(scan, 'name', '') || _.get(scan, 'Name', ''),
  commonCode: _.get(scan, 'common_code', '') || _.get(scan, 'CommonCode', ''),
  createdAt: _.get(scan, 'created_at', '') || _.get(scan, 'CreatedAt', ''),
  fileSize: _.get(scan, 'file_size', 0) || _.get(scan, 'FileSize', 0),
  firstUploadedAt: _.get(scan, 'first_uploaded_at', '') || _.get(scan, 'FirstUploadedAt', ''),
  traceID: _.get(scan, 'trace_id', '') || _.get(scan, 'TraceId', ''),
  updatedAt: _.get(scan, 'updated_at', '') || _.get(scan, 'UpdatedAt', ''),
  minUrl: _.get(scan, 'min_url', '') || _.get(scan, 'MinUrl', ''),
  minFileSize: _.get(scan, 'min_file_size', 0) || _.get(scan, 'MinFileSize', 0),
  url: _.get(scan, 'url', '') || _.get(scan, 'Url', ''),
  fileName: _.get(scan, 'Url', '').split('/').pop() ?? ''
})

export const bundleMap = ({ bundle }) => ({
  id: bundle?.id ?? bundle?.Id,
  name: bundle?.name ?? bundle?.Name,
  code: bundle?.code ?? bundle?.Code,
  canClean: bundle?.can_clean ?? bundle?.CanClean,
  canDelete: bundle?.can_delete ?? bundle?.CanDelete,
  canRename: bundle?.can_rename ?? bundle?.CanRename,
  canUpload: bundle?.can_upload ?? bundle?.CanUpload,
  scans: bundle.scans ? _.map((_.get(bundle, 'scans', [])), scan => scanMap({ scan })) : _.map((_.get(bundle, 'Scans', [])), scan => scanMap({ scan }))
})

export const scanMapToApi = ({ scan }) => ({
  Name: _.get(scan, 'name', ''),
  CommonCode: _.get(scan, 'commonCode', '')
})
