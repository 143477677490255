import _ from 'lodash'

export const goodsMap = ({ goods }) => ({
  id: _.get(goods, 'id', '') || _.get(goods, '_id', ''),
  name: _.get(goods, 'name', ''),
  price: Number(_.get(goods, 'price', 0.0)),
  category: {
    id: goods?.category?.id ?? goods?.category?._id,
    name: _.get(goods, 'category.name', '')
  },
  count: Number(_.get(goods, 'count', 1)),
  manufacturer: _.get(goods, 'manufacturer', '')
})

export const segmentPlaceholders = [
  {
    segment: 'Бытовая техника',
    name: 'Пылесос Bosch BCS6',
    producer: 'Bosch'
  },
  {
    segment: 'Мобильные телефоны',
    name: 'iPhone 12 MGJ53RU',
    producer: 'Apple'
  },
  {
    segment: 'Туризм',
    name: 'Москва (7д./6н.)',
    producer: 'Россия'
  },
  {
    segment: 'Одежда',
    name: 'Норковая шуба 1746',
    producer: 'Россия, Каляев'
  },
  {
    segment: 'Мебель',
    name: 'Диван Альба',
    producer: 'Россия, Ангстрем'
  },
  {
    segment: 'Мототехника',
    name: 'Скутер BMW C600',
    producer: 'BMW'
  },
  {
    segment: 'Фитнес',
    name: 'Абонемент на год',
    producer: 'Россия'
  },
  {
    segment: 'Ювелирные изделия',
    name: 'Кольцо золотое 585',
    producer: 'Sokolov'
  },
  {
    segment: 'Быстровозводимые дома',
    name: 'Дом Витязь 1',
    producer: 'Россия'
  },
  {
    segment: 'Автомобильные товары',
    name: 'Багажник Amos Alfa',
    producer: 'Thule'
  },
  {
    segment: 'Товары для дома/строительства',
    name: 'Окно VEKA SOFTLINE',
    producer: 'VEKA'
  },
  {
    segment: 'Медицинские услуги',
    name: 'Оксигенотерапия',
    producer: 'Россия'
  },
  {
    segment: 'Обучение',
    name: 'Курсы англ. языка',
    producer: 'Россия'
  },
  {
    segment: 'Окна/Двери',
    name: 'Дверь входная Дуэт',
    producer: 'Torex'
  },
  {
    segment: 'Спортивные товары',
    name: 'Лыжи Elan FusionX',
    producer: 'Fischer'
  }
]
