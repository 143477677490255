import _ from 'lodash'

export const phoneSimpleMap = ({ phone }) => {
  if (!phone) return ''
  return `${phone.code || ''}${phone.number || ''}`
}

export const phoneSimpleMapApi = ({ phone }) => ({
  _type: 'Phone',
  type: 'Mobile',
  number: phone.substring(3),
  area: '7',
  code: phone.substring(0, 3),
  view: `7 ${phone.substring(0, 3)} ${phone.substring(3)}`
})

export const phoneSimpleShortMapApi = ({ phone }) => ({
  number: phone.substring(3),
  area: '7',
  code: phone.substring(0, 3)
})

export const phoneMap = ({ phone }) => ({
  area: _.get(phone, 'area', ''),
  code: _.get(phone, 'code', ''),
  extension: _.get(phone, 'extension', ''),
  number: _.get(phone, 'number', ''),
  type: _.get(phone, 'type', ''),
  commonType: _.get(phone, '_type', ''),
  view: _.get(phone, 'view', '')
})
