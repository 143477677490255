import _ from 'lodash'
import { moment, DATE_FORMATS, DATE_FORMAT, isSafe } from 'uikit'
import { phoneSimpleMapApi, phoneSimpleMap } from './phone'
import { EMPTY_AG_ID, EMPTY_AG_NAME } from './agentCommissionGroup'
import { notNull } from './null'

const USER_STATUSES = [
  {
    value: 'working',
    label: 'Работает'
  },
  {
    value: 'fired',
    label: 'Уволен'
  }
]

const shortUserMap = ({ user }) => ({
  id: user.id ?? user._id,
  login: _.get(user, 'login', ''),
  fullName: _.get(user, 'fullname', ''),
  email: _.get(user, 'email', ''),
  role: _.get(user, 'role', ''),
  isAccessGrant: _.get(user, 'access_granted', false),
  lastActivity: user.last_activity_at ? moment(user.last_activity_at, DATE_FORMATS).toDate() : null,
  isActive: user?.enabled ?? user?.active ?? true,
  isProcessLock: false,
  lockReason: _.get(user, 'lock_reason', ''),
  workerType: _.get(user, 'user_type', null)
})

const userMap = ({ user }) => ({
  id: user.id ?? user._id,
  isActive: user?.enabled ?? user?.active ?? true,
  lockReason: _.get(user, 'lock_reason', ''),
  login: _.get(user, 'login', ''),
  firstName: user?.first_name ?? '',
  lastName: user?.last_name ?? '',
  patronymic: user?.middle_name ?? '',
  fullName: user?.fullname ?? '',
  fio: _.get(user, 'fullname', ''),
  password: _.get(user, 'password', ''),
  manager: notNull(user.supervisor, supervisor => ({
    id: supervisor.id ?? supervisor._id,
    name: supervisor.fullname ?? supervisor.login
  })),
  accessRole: notNull(user.access_role, access_role => ({
    id: access_role.id ?? access_role._id,
    name: access_role.name ?? access_role.name,
    behaviors: access_role.behaviors ?? access_role.behaviors
  })),
  workerType: _.get(user, 'user_type', null),
  hireType: _.get(user, 'hire_type', null),
  status: _.get(user, 'status', null),
  qrSpec: _.get(user, 'qr_code', '') ?? '',
  email: _.get(user, 'email', ''),
  phone: user.mobile_number ? user.mobile_number : (user.mobile_phone ? phoneSimpleMap({ phone: user.mobile_phone }) : ''),
  showAgentCommission: _.get(user, 'show_agent_commission'),
  callCenters: _.map(_.get(user, 'call_centers', []), c => ({
    id: c.id ?? c._id,
    name: _.get(c, 'name', '')
  })),
  organizations: _.map(_.get(user, 'organizations', []), o => ({
    id: o.id ?? o._id,
    name: _.get(o, 'name', '')
  })),
  points: _.map(_.get(user, 'points', []), p => ({
    id: p.id ?? p._id,
    name: _.get(p, 'name', '')
  })),
  shortQuestionaryPoints: _.map(_.get(user, 'short_questionary_points', []), p => ({
    id: p.id ?? p._id,
    name: _.get(p, 'name', '')
  })),
  macroRegions: _.map(_.get(user, 'accessible_macroregions', []), a => ({
    id: a.id ?? a._id,
    name: _.get(a, 'name', '')
  })),
  agentCommissionGroups: _.map(_.get(user, 'agent_commission_groups', []), pointACGroup => ({
    agentCommissionGroup: notNull(pointACGroup.agent_commission_group, acGroup => ({
      id: _.get(acGroup, 'id', ''),
      name: _.get(acGroup, 'name', '')
    })) ?? {
      id: EMPTY_AG_ID,
      name: EMPTY_AG_NAME
    },
    startsOn: pointACGroup.starts_on ? moment(moment(pointACGroup.starts_on).toDate() - (moment(pointACGroup.starts_on).toDate().getTimezoneOffset() * 60000)).startOf('month') : null
  })),
  isEmailConfirmed: !!user?.email_confirmed,
  isMobilePhoneConfirmed: !!user?.mobile_phone_confirmed,
  isPasswordActivationAvailable: !!user?.password_activation_available,
  comment: _.get(user, 'comment', '')
})

const userMapApi = ({ user }) => {
  const resolve = {
    login: user.login,
    last_name: user.lastName,
    first_name: user.firstName,
    supervisor_id: user.manager,
    access_role_id: user.accessRole,
    user_type: user.workerType,
    hire_type: user.hireType,
    status: user.status,
    qr_code: user.qrSpec !== '' ? user.qrSpec : null,
    email: user.email,
    mobile_phone: phoneSimpleMapApi({ phone: user.phone }),
    mobile_number: user.phone,
    show_agent_commission: user.showAgentCommission,
    call_center_ids: _.map(user.callCenters, c => c?.id ?? c),
    organization_ids: _.map(user.organizations, p => p?.key ?? p?.id ?? p),
    point_ids: _.map(user.points, p => p?.key ?? p?.id ?? p),
    short_questionary_point_ids: _.map(user.shortQuestionaryPoints, p => p?.key ?? p?.id ?? p),
    accessible_macroregion_ids: _.map(user.macroRegions, c => c?.id ?? c),
    self_password: user.selfPassword,
    send_invite: user?.isSendInvite,
    send_confirmation: user.isSendConfirmation
  }

  if (user?.patronymic) resolve.middle_name = user?.patronymic

  if (user.password) resolve.password = user.password
  if (user.lockReason !== '') resolve.lock_reason = user.lockReason
  return resolve
}

const userCodeMap = ({ code }) => ({
  peerID: _.get(code, 'peer_id', ''),
  values: _.chain(_.get(code, 'values', []))
    .map(value => ({
      name: _.get(value, 'name', ''),
      value: _.get(value, 'value', '')
    }))
    .filter(value => isSafe(value.value) && isSafe(value.name))
    .value()
})

const userCodeMapApi = ({ code }) => ({
  peer_id: code.peerID,
  values: _.map(code.values, value => ({
    name: value.name,
    value: value.value
  }))
})

const userCodeHistoryMap = ({ history }) => ({
  id: history.id ?? history._id,
  author: notNull(history?.author, mod => ({
    id: mod.id ?? mod._id,
    login: _.get(mod, 'login', ''),
    fullName: _.get(mod, 'fullname', ''),
    email: _.get(mod, 'email', ''),
    isAccessGranted: _.get(mod, 'access_granted', false),
    lastActivityAt: mod.last_activity_at ? moment(mod.last_activity_at, DATE_FORMATS).toDate() : null
  })),
  modifiedAt: history.modified_at ? moment(history.modified_at, DATE_FORMATS).toDate() : null,
  modifications: _.get(history, 'modifications', [])
})

export const userACGroupMapApi = ({ acGroups }) => _.map(acGroups, acGroup => ({
  agent_commission_group_id: acGroup.agentCommissionGroup.id !== EMPTY_AG_ID ? acGroup.agentCommissionGroup.id : null,
  starts_on: moment(acGroup.startsOn, DATE_FORMATS).format(DATE_FORMAT)
}))

export { USER_STATUSES, shortUserMap, userMap, userMapApi, userCodeMapApi, userCodeMap, userCodeHistoryMap }
