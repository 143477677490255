export const FAMILY_STATUS_MARRIED = 'married' // женат/замужем
export const FAMILY_STATUS_CIVIL_MARRIAGE = 'civil_marriage' // гражданский брак

export const EMPLOYMENT_STATUS_PERMANENT = 'permanent' // постоянная занятость
export const EMPLOYMENT_STATUS_RETIREE = 'retiree' // работающий пенсионер
export const EMPLOYMENT_STATUS_STUDENT = 'student' // студент
export const EMPLOYMENT_STATUS_NOT_WORKING = 'non_working' // не работает
export const EMPLOYMENT_STATUS_HOUSE_WIFE = 'housewife' // домохозяйка / В декретном отпуске
export const EMPLOYMENT_STATUSES_WITHOUT_WORK = [
  EMPLOYMENT_STATUS_RETIREE,
  EMPLOYMENT_STATUS_STUDENT,
  EMPLOYMENT_STATUS_NOT_WORKING,
  EMPLOYMENT_STATUS_HOUSE_WIFE
]

export const INCOMING_TYPE_PRIMARY_JOB = 'primary_job' // от трудовой деятельности по основному месту работы

export const GROUNDS_TYPE_PROXY = 'proxy' // доверенность
