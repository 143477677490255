import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { moment, DATE_FORMATS, DATE_FORMAT, safeObject, isSafe } from 'uikit'
import { EMPLOYMENT_STATUSES_WITHOUT_WORK, FAMILY_STATUS_CIVIL_MARRIAGE, FAMILY_STATUS_MARRIED } from 'utils/dict'
import { shortUserMap } from './user'
import { shortPointMap } from './point'
import { scanMap, BORROWER_PHOTO } from './poscans'
import { addressMap, addressMapApi } from './address'
import { bankMap } from './bank'
import { goodsMap } from './goods'
import { serviceMap } from './pickup'
import { refundMap } from './refund'

export const MAIN_STEP = 'main'
export const ORDERS_STEP = 'orders'
export const CALC_STEP = 'calc'
export const ADDITIONAL_STEP = 'additional'
export const CUSTOM_STEP = 'custom'
export const PHONE_STEP = 'phone'
export const PHONE_CONFIRMATION_STEP = 'phone_confirmation'
export const PD_AGREEMENT_STEP = 'pd_agreement'
export const DASHBOARD_STEP = 'dashboard'

export const QUESTIONARY_STATE_INITIAL = 'initial' // Заполнение заявки
export const QUESTIONARY_STATE_NEW = 'new' // Ожидает обработки КЦ
export const QUESTIONARY_STATE_REGISTERED = 'registered' // Отправка в банк
export const QUESTIONARY_STATE_ASSIGNED = 'assigned' // В обработке КЦ
export const QUESTIONARY_STATE_CANCELED = 'canceled' // Отменена оператором КЦ
export const QUESTIONARY_STATE_POSITIVE_DECISION = 'positive_decision' // Кредит не выдан
export const QUESTIONARY_STATE_NO_POSITIVE_DECISION = 'no_positive_decision' // Отказано
export const QUESTIONARY_STATE_CLIENT_REFUSED = 'client_refused' // Отказ клиента от предложений
export const QUESTIONARY_STATE_CLIENT_REJECTED = 'rejected' // Отменена
export const QUESTIONARY_STATE_PROCESSED = 'processed' // Обработано оператором КЦ
export const QUESTIONARY_STATE_CREDIT_ISSUED = 'credit_issued' // Кредит выдан
export const QUESTIONARY_STATE_CREDIT_REFUNDED = 'credit_refunded' // Кредит возвращен
export const QUESTIONARY_STATE_DELAY = 'delayed' // Заявка отложена
export const QUESTIONARY_STATE_FINISHED = 'finished' // Заявка завершена

export const generateLink = ({ questionary, isCheckApiVersion = true, creditRequestId = null }) => {
  if (questionary.step === DASHBOARD_STEP) return `/questionaries/${questionary.id}/dashboard`
  if (isCheckApiVersion && questionary.apiVersion !== 'v2') return null

  if (!questionary.step) {
    const link = `/questionaries/${questionary.id}`
    return creditRequestId ? `${link}?creditRequest=${creditRequestId}` : link
  }

  const link = `/questionaries/${questionary.id}?step=${questionary.step}`
  return creditRequestId ? `${link}&creditRequest=${creditRequestId}` : link
}

export const currentStep = step => {
  switch (step) {
    case 'main':
      return MAIN_STEP
    case 'items':
      return ORDERS_STEP
    case 'calc':
      return CALC_STEP
    case 'additional':
      return ADDITIONAL_STEP
    case 'custom':
      return CUSTOM_STEP
    case 'dashboard':
      return DASHBOARD_STEP
    default:
      return 'main'
  }
}

export const currentMetaStep = step => {
  switch (step) {
    case 'phone':
      return PHONE_STEP
    case 'pd_agreement':
      return PD_AGREEMENT_STEP
    default:
      return ''
  }
}

export const questionaryMap = (params = {}) => {
  const questionary = params?.id ? params : params?.questionary
  const options = !_.isEmpty(params?.options) ? params?.options : questionary?.options

  let resolve = {
    id: questionary?.id,
    step: isSafe(questionary?.step) ? currentStep(questionary?.step) : null,
    metaStep: isSafe(questionary?.sub_step) ? currentMetaStep(questionary?.sub_step) : null,

    point: questionary?.point ? shortPointMap({ point: questionary?.point }) : null,
    user: questionary?.owner ? shortUserMap({ user: questionary?.owner }) : null
  }

  const lastName = _.get(questionary, 'lastname', '') || ''
  const firstName = _.get(questionary, 'firstname', '') || ''
  const patronymic = _.get(questionary, 'middlename', '') || ''
  let fullName = lastName && firstName ? `${lastName} ${firstName}` : ''
  if (patronymic) fullName += ` ${patronymic}`

  const passportSeries = _.get(questionary, 'documents.passport.series', '') || _.get(questionary, 'passport.series', '')
  const passportNumber = _.get(questionary, 'documents.passport.number', '') || _.get(questionary, 'passport.number', '')
  const passportDate = questionary?.documents?.passport?.issued_on ? moment(questionary?.documents?.passport?.issued_on, DATE_FORMATS).toDate() :
    questionary?.passport?.issued_on ? moment(questionary?.passport?.issued_on, DATE_FORMATS).toDate() : null

  const prevPassportSeries = _.get(questionary, 'documents.passport.prev_series', '')
  const prevPassportNumber = _.get(questionary, 'documents.passport.prev_number', '')

  resolve = {
    ...resolve,

    // Main data
    clientId: questionary?.client_id,
    id: questionary?.id ?? questionary?._id,
    lastName,
    firstName,
    patronymic,
    fullName: fullName || '',
    gender: _.get(questionary, 'sex', 'male') === 'male' ? 1 : 2,
    phone: _.get(questionary, 'mobile_phone', '').substr(1),
    passportSeries,
    passportNumber,
    passport: `${passportSeries}${passportNumber}`,
    passportDate,
    birthDate: questionary?.birthdate ? moment(questionary?.birthdate, DATE_FORMATS).toDate() : null,
    income: questionary?.income ? Number(questionary?.income) : '',
    isCheat: _.get(questionary, 'suspicious_borrower', false),

    // Order data
    orders: _.map(questionary?.goods, order => ({
      orderName: _.get(order, 'name', ''),
      producer: _.get(order, 'manufacturer', ''),
      cost: Number(_.get(order, 'price', 0)),
      count: Number(_.get(order, 'count', 0)),
      category: {
        id: _.get(order, 'category.id', null),
        name: _.get(order, 'category.name', null)
      }
    })),

    // Pickup data
    calculationId: _.get(questionary, 'pickup_result_id', null),
    signedProducts: _.map(_.get(questionary, 'signed_products', []), product => ({
      bankId: product.bank_id,
      id: product.prefer_product_id
    })),

    // Additional data
    passportCode: questionary?.documents?.passport?.issuer_code ?? '',
    passportBy: questionary?.documents?.passport?.issued_by ?? '',
    birthPlace: _.get(questionary, 'birthplace', ''),
    addressRegistration: questionary?.registration_address ? addressMap({ address: questionary?.registration_address }) : null,
    addressRegistrationFull: typeof questionary?.registration_address === 'string' ? questionary?.registration_address : questionary?.registration_address?.full,
    registrationDate: _.get(questionary, 'registration_address.starts_on') ?
      moment(_.get(questionary, 'registration_address.starts_on'), DATE_FORMATS).toDate() : _.get(questionary, 'registration_address_starts_on'),
    residenceAddress: questionary?.residence_address ? addressMap({ address: questionary?.residence_address }) : null,
    residenceAddressFull: typeof questionary?.residence_address === 'string' ? questionary?.residence_address : questionary?.residence_address?.full,
    residenceDate: _.get(questionary, 'residence_address.starts_on') ?
      moment(_.get(questionary, 'residence_address.starts_on'), DATE_FORMATS).toDate() : _.get(questionary, 'residence_address_starts_on'),
    prevPassport: `${prevPassportSeries}${prevPassportNumber}`,
    prevPassportSeries,
    prevPassportNumber,

    // Other data
    keyword: questionary?.keyword,
    email: questionary?.email,

    education: questionary?.education_type,

    familyType: questionary?.marital_status,
    familyChildren: Number(questionary?.children_count)
  }

  if (resolve.familyType === FAMILY_STATUS_MARRIED || resolve.familyType === FAMILY_STATUS_CIVIL_MARRIAGE) {
    const sLastName = questionary?.spouse?.lastname ?? ''
    const sFirstName = questionary?.spouse?.firstname ?? ''
    const sPatronymic = questionary?.spouse?.middlename ?? ''
    resolve.familySpouseLastName = sLastName
    resolve.familySpouseFirstName = sFirstName
    resolve.familySpousePatronymic = sPatronymic
    resolve.familySpouseFullName = `${sLastName} ${sFirstName}`
    if (sPatronymic) {
      resolve.familySpouseFullName += ` ${sPatronymic}`
    }
  } else {
    resolve.familySpouseLastName = ''
    resolve.familySpouseFirstName = ''
    resolve.familySpouseLastName = ''
    resolve.familySpousePatronymic = ''
  }

  resolve.employmentType = questionary?.employment_type
  if (_.findIndex(EMPLOYMENT_STATUSES_WITHOUT_WORK, e => e === resolve.employmentType) === -1) {
    resolve.organizationAddress = questionary?.employment?.organization_address ? addressMap({ address: questionary?.employment?.organization_address }) : null
    resolve.employmentDate = questionary?.employment?.starts_on ? moment(questionary?.employment?.starts_on, DATE_FORMATS).toDate() : null
    resolve.activityType = questionary?.employment?.activity_line_id
    resolve.bussinessType = questionary?.employment?.activity_id
    resolve.organization = questionary?.employment?.organization_name
    resolve.organizationInn = questionary?.employment?.tin || ''
    resolve.ownershipType = questionary?.employment?.ownership_type
    resolve.employmentPosition = questionary?.employment?.position
    resolve.workPhone = `${questionary?.employment?.phone}`.substring(1)
    resolve.landlinePhone = ''
  } else {
    resolve.organizationAddress = null
    resolve.employmentDate = null
    resolve.activityType = null
    resolve.bussinessType = null
    resolve.organization = ''
    resolve.organizationInn = ''
    resolve.ownershipType = null
    resolve.employmentPosition = ''
    resolve.workPhone = ''
    resolve.landlinePhone = `${questionary?.landline_phone}`.substring(1)
  }

  resolve.incoming = _.map(questionary?.incomes, income => ({
    type: income.category,
    price: Number(income.sum)
  }))

  if (resolve.incoming.length === 0 && questionary?.income) {
    resolve.incoming = [{ type: null, price: Number(questionary.income) }]
  }

  resolve.contactFirstName = _.get(questionary, 'contact_people.0.firstname', '')
  resolve.contactPatronymic = _.get(questionary, 'contact_people.0.middlename', '') || ''
  resolve.contactPhone = _.get(questionary, 'contact_people.0.phone', '').substring(1)
  resolve.contactStatus = _.get(questionary, 'contact_people.0.relation_type', null)

  resolve.realty = _.map(questionary?.realties, r => ({
    type: r.type
  }))

  resolve.auto = _.map(questionary?.cars, r => ({
    producer: _.get(r, 'manufacturer', ''),
    model: _.get(r, 'model', ''),
    number: _.get(r, 'number', ''),
    year: String(_.get(r, 'year', ''))
  }))

  resolve.documents = []
  if (questionary?.documents?.tin) {
    resolve.documents.push({
      type: 'tin',
      series: _.get(questionary?.documents?.tin, 'series', ''),
      number: _.get(questionary?.documents?.tin, 'number', ''),
      inn: _.get(questionary?.documents?.tin, 'tin_number', ''),
      date: _.get(questionary?.documents?.tin, 'issued_on') ?
        moment(_.get(questionary?.documents?.tin, 'issued_on'), DATE_FORMATS).toDate() : null
    })
  }
  if (questionary?.documents?.driver_license) {
    resolve.documents.push({
      type: 'driver',
      series: _.get(questionary?.documents?.driver_license, 'series', ''),
      number: _.get(questionary?.documents?.driver_license, 'number', ''),
      by: _.get(questionary?.documents?.driver_license, 'issued_by', ''),
      date: _.get(questionary?.documents?.driver_license, 'issued_on') ?
        moment(_.get(questionary?.documents?.driver_license, 'issued_on'), DATE_FORMATS).toDate() : null,
      dateBefore: _.get(questionary?.documents?.driver_license, 'valid_till') ?
        moment(_.get(questionary?.documents?.driver_license, 'valid_till'), DATE_FORMATS).toDate() : null
    })
  }
  if (questionary?.documents?.snils) {
    resolve.documents.push({
      type: 'snils',
      number: _.get(questionary?.documents?.snils, 'number', '')
    })
  }

  resolve.packageId = questionary?.poscans_package_id ??
    questionary?.documents_package?.package_id ?? null

  resolve.qrCode = questionary?.documents_package?.qr_code ?? ''

  const documentsPackage = _.map(questionary?.documents_package?.scans, scan => scanMap({ scan }))
  resolve.photo = _.find(documentsPackage, p => p.commonCode === BORROWER_PHOTO) || null
  resolve.scans = _.filter(documentsPackage, scan => scan.commonCode !== BORROWER_PHOTO)

  // Options
  resolve.options = {
    photo: options?.photo ? {
      photoBanks: _.get(options, 'photo.needed_for', [])
    } : null,
    pdAgreement: options?.pd_agreement ? {
      packageId: _.get(options, 'pd_agreement.package_id', null),
      qrCode: _.get(options, 'pd_agreement.qr_code', '')
    } : null,
    isDocs: _.get(options, 'additional_documents', false),
    isRealty: _.get(options, 'realty', false),
    isAuto: _.get(options, 'vehicles', false),
    isOwnerChange: _.get(options, 'owner_change', false),
    callCenter: options?.call_center ? {
      isPhoneConfirmationRequired: _.get(options, 'call_center.phone_confirmation_required', false),
      isScansRequired: _.get(options, 'call_center.scans_required', false),
      isTransfer: _.get(options, 'call_center.transfer', false)
    } : null
  }

  return resolve
}

export const preparePickupOffersSelected = offers => _.flow(
  list => _.filter(list, l => !l.isMissOffers && !l.isProcessGetOffers),
  list => _.reduce(list, (s, r) => [...s, ...r.offers], []),
  list => _.filter(list, l => l.isSelect && _.isEmpty(l.errors)),
  list => _.map(list, l => ({ bank_id: l.bank.id, prefer_product_id: l.id }))
)(offers)

export const questionaryMapApi = ({ questionary, step = MAIN_STEP }) => {
  let resolve = {}

  if (questionary?.pointId) {
    resolve.point_id = questionary?.pointId
  }
  if (questionary?.userId) {
    resolve.owner_id = questionary?.userId
  }
  if (questionary?.creditRequestId) {
    resolve.credit_request_id = questionary?.creditRequestId
  }
  if (questionary?.packageId) {
    resolve.poscans_package_id = questionary?.packageId
  }

  switch (step) {
    case MAIN_STEP: {
      resolve = {
        ...resolve,
        current_step: 'main',
        lastname: questionary?.lastName,
        firstname: questionary?.firstName,
        sex: questionary?.gender === 1 ? 'male' : 'female',
        mobile_phone: `7${questionary?.phone}`,
        passport: safeObject({
          series: questionary?.passportSeries,
          number: questionary?.passportNumber,
          issued_on: moment(questionary?.passportDate, DATE_FORMATS).format(DATE_FORMAT),
          issuer_code: questionary?.passportCode || null,
          issued_by: questionary?.passportBy || null
        }),
        birthdate: moment(questionary?.birthDate, DATE_FORMATS).format(DATE_FORMAT),
        income: Number(questionary?.income),
        suspicious_borrower: questionary?.isCheat,
        birthplace: questionary?.birthPlace
      }

      if (questionary?.addressRegistration) {
        resolve.registration_address = addressMapApi({ address: questionary?.addressRegistration })

        resolve.registration_address.starts_on = questionary?.registrationDate ? moment(questionary?.registrationDate, DATE_FORMATS).format(DATE_FORMAT) : null
      }

      if (questionary?.residenceAddress) {
        resolve.residence_address = addressMapApi({ address: questionary?.residenceAddress })
        resolve.residence_address.starts_on = questionary?.residenceDate ? moment(questionary?.residenceDate, DATE_FORMATS).format(DATE_FORMAT) : null
      }

      if (questionary?.clientId) resolve.client_id = questionary?.clientId
      if (questionary?.patronymic) resolve.middlename = questionary?.patronymic
      break
    }
    case ORDERS_STEP: {
      resolve = {
        ...resolve,
        current_step: 'items',
        goods: _.map(questionary?.orders, order => ({
          name: order.orderName,
          manufacturer: order.producer,
          price: Number(order.cost),
          count: Number(order.count),
          category_id: order.category
        }))
      }
      break
    }
    case CALC_STEP: {
      resolve = {
        ...resolve,
        current_step: 'calc',
        pickup_result_id: questionary?.calculationId,
        selected_products: preparePickupOffersSelected(questionary?.offers)
      }
      break
    }
    case ADDITIONAL_STEP:
      resolve = {
        ...resolve,
        current_step: 'additional',
        passport: {
          series: questionary?.passportSeries,
          number: questionary?.passportNumber,
          issued_on: moment(questionary?.passportDate, DATE_FORMATS).format(DATE_FORMAT),
          issuer_code: questionary?.passportCode,
          issued_by: questionary?.passportBy
        },
        birthplace: questionary?.birthPlace,

        registration_address: {
          ...questionary?.addressRegistration ? addressMapApi({ address: questionary?.addressRegistration }) : null,
          starts_on: questionary?.registrationDate ? moment(questionary?.registrationDate, DATE_FORMATS).format(DATE_FORMAT) : null
        },

        residence_address: {
          ...questionary?.residenceAddress ? addressMapApi({ address: questionary?.residenceAddress }) : null,
          starts_on: questionary?.residenceDate ? moment(questionary?.residenceDate, DATE_FORMATS).format(DATE_FORMAT) : null
        }
      }

      if (questionary?.prevPassport) {
        resolve.previous_passport = {
          series: questionary?.prevPassportSeries,
          number: questionary?.prevPassportNumber
        }
      }

      break

    case CUSTOM_STEP:
      resolve = {
        ...resolve,
        current_step: 'custom',
        keyword: questionary?.keyword,
        email: questionary?.email,

        education_type: questionary?.education,

        marital_status: questionary?.familyType,
        children_count: Number(questionary?.familyChildren)
      }

      if (questionary?.familyType === FAMILY_STATUS_MARRIED || questionary?.familyType === FAMILY_STATUS_CIVIL_MARRIAGE) {
        resolve.spouse = {
          lastname: questionary?.familySpouseLastName,
          firstname: questionary?.familySpouseFirstName
        }
        if (questionary?.familySpousePatronymic) {
          resolve.spouse.middlename = questionary?.familySpousePatronymic
        }
      }

      resolve.employment_type = questionary?.employmentType
      if (_.findIndex(EMPLOYMENT_STATUSES_WITHOUT_WORK, e => e === questionary?.employmentType) === -1) {
        resolve.employment = {
          organization_address: questionary?.organizationAddress ? addressMapApi({ address: questionary?.organizationAddress }) : null,
          starts_on: questionary?.employmentDate ? moment(questionary?.employmentDate, DATE_FORMATS).format(DATE_FORMAT) : null,
          activity_id: questionary?.bussinessType,
          activity_line_id: questionary?.activityType,
          organization_name: questionary?.organization,
          ownership_type: questionary?.ownershipType,
          position: questionary?.employmentPosition,
          phone: `7${questionary?.workPhone}`,
          email: questionary?.email
        }
        if (questionary?.organizationInn !== '') {
          resolve.employment.tin = questionary?.organizationInn
        }
        resolve.landline_phone = ''
      } else {
        resolve.landline_phone = `7${questionary?.landlinePhone}`
      }

      resolve.incomes = _.map(questionary?.incoming, income => ({
        category: income.type,
        sum: Number(income.price)
      }))

      resolve.contact_people = [safeObject({
        firstname: questionary?.contactFirstName,
        middlename: questionary?.contactPatronymic !== '' ? questionary?.contactPatronymic : null,
        phone: `7${questionary?.contactPhone}`,
        relation_type: questionary?.contactStatus
      })]

      if (questionary?.isRealty) {
        resolve.realties = _.map(questionary?.realty, r => ({
          type: r.type
        }))
      }

      if (questionary?.isAuto) {
        resolve.cars = _.map(questionary?.auto, r => ({
          manufacturer: r.producer,
          model: r.model,
          number: r.number,
          year: r.year
        }))
      }

      if (questionary?.isDocs) {
        _.each(questionary?.documents, doc => {
          switch (doc.type) {
            case 'tin':
              resolve.tin = {
                series: doc.series,
                number: doc.number,
                tin_number: doc.inn,
                issued_on: doc.date ? moment(doc.date, DATE_FORMATS)
                  .format(DATE_FORMAT) : null
              }
              break
            case 'driver':
              resolve.driver_license = {
                series: doc.series,
                number: doc.number,
                issued_by: doc.by,
                issued_on: doc.date ? moment(doc.date, DATE_FORMATS)
                  .format(DATE_FORMAT) : null,
                valid_till: doc.dateBefore ? moment(doc.dateBefore, DATE_FORMATS)
                  .format(DATE_FORMAT) : null
              }
              break
            case 'snils':
              resolve.snils = {
                number: doc.number
              }
              break
          }
        })
      }

      resolve.without_photo = questionary?.isWithoutPhoto

      break

    case PHONE_CONFIRMATION_STEP: {
      resolve = {
        ...questionary
      }
      break
    }

    case PD_AGREEMENT_STEP:
      resolve = {
        ...resolve,
        current_step: 'pd_agreement'
      }
  }
  return resolve
}

export const APPLICATION_POSITIVE_DECISION_STATE = 'positive_decision'
export const APPLICATION_NEGATIVE_DECISION_STATE = 'negative_decision'
export const APPLICATION_WAITING_STATE = 'register_waiting'
export const APPLICATION_NEED_DATA_STATE = 'need_data'

export const shortApplicationMap = ({ application }) => ({
  bank: bankMap({ bank: application?.bank }),
  currentState: application?.current_state,
  currentStateHuman: application?.current_state_human
})

export const SERVICE_EXCLUDED_TEXT = 'Кредитный продукт будет оформлен без некоторых доп. услуг'

export const OFFER_ACTION_ATTACH_SCANS = 'attach_scans' // Отправка сканов
export const OFFER_ACTION_SELECT = 'select' // Выбрать
export const OFFER_ACTION_MARK_AS_SELECT = 'mark_as_select' // Отметить как выбранное
export const OFFER_ACTION_CONFIRM = 'confirm' // Выдать
export const OFFER_ENTER_SES_CODE = 'enter_ses_code' // Ввести код ПЭП
export const OFFER_RESEND_SES_CODE = 'resend_ses_code' // Запросить код ПЭП заново
export const OFFER_ACTION_SELECT_EXTERNAL = 'select_external' // Проверить в Siebel
export const OFFER_ACTION_FALLBACK_TO_MANUAL_SIGN = 'fallback_to_manual_sign' // Перейти на ручное подписание
export const OFFER_ACTION_CHECK_EXTERNAL = 'check_external' // Проверить статус
export const OFFER_ACTION_RETRY_REGISTRATION = 'retry_registration' // Повторить регистрацию

export const productMap = ({ product }) => ({
  id: product?.id ?? product?._id ?? product?.offer_id,
  tempId: product.tempId || uuid(),
  appId: product?.bank_application_id,
  systemName: product?.bank?.system_name ?? product?.product_bank,
  memoUrl: product?.bank?.memo_url ?? '',
  maxFilesSize: product?.bank?.max_files_size ?? {},
  state: _.get(product, 'state', ''),
  stateHuman: _.get(product, 'state_human', ''),
  statusText: _.get(product, 'status_text', ''),
  groupStatus: _.get(product, 'group_status_name', ''),
  groupStatusHumanName: _.get(product, 'group_status_human_name', ''),
  isServicesExcluded: _.get(product, 'services_excluded', false),
  primary: product?.options?.primary_text ? {
    title: _.get(product, 'options.primary_text.title', ''),
    text: _.get(product, 'options.primary_text.text', '')
  } : null,
  bankInfo: product?.options?.bank_info ? {
    title: _.get(product, 'options.bank_info.title', ''),
    text: _.get(product, 'options.bank_info.text', ''),
    type: _.get(product, 'options.bank_info.type', '')
  } : null,
  warning: product?.options?.warning_text ? {
    title: _.get(product, 'options.warning_text.title', '')
  } : null,
  isEnterSesCode: _.get(product, 'options.enter_code_on_confirm', false),
  sesCodeSize: _.get(product, 'options.ses_code_size', null),
  isSesRepeatClient: _.get(product, 'options.ses_repeat_client', false),
  isEnterDate: _.get(product, 'options.show_date_dialog_on_select', false),
  isEnterEanCardNumber: _.get(product, 'options.show_ean_card_number_dialog_on_select', false),
  enterD2Items: _.map(product?.options?.show_d2_garant_master_dialog_on_select?.items, item => ({
    id: _.get(item, 'id', ''),
    manufacturer: _.get(item, 'manufacturer', ''),
    name: _.get(item, 'name', ''),
    price: _.get(item, 'price', 0),
    serialNumber: _.get(item, 'serial_number', '')
  })),
  services: _.map(product?.services, service => ({
    ...serviceMap({ service }),
    conditionsUrl: _.get(service, 'conditions_url', '')
  })),
  otherBankServices: _.map(product?.other_bank_services, service => (
    serviceMap({ service, isShort: true })
  )),
  insurance: product?.insurance_offer ? {
    name: _.get(product, 'insurance_offer.name', ''),
    price: product?.insurance_offer.amount ? Number(product?.insurance_offer.amount) : 0
  } : null,
  errorDetails: _.get(product, 'error_details', ''),
  productName: _.get(product, 'product_name', ''),
  term: product?.term ? Number(product.term) : 0,
  initialPayment: product?.initial_payment ? Number(product.initial_payment) : 0,
  percent: product?.tax ? Number(product.tax) : 0,
  creditSum: product?.amount ? Number(product.amount) : 0,
  creditId: _.get(product, 'credit_id', ''),
  monthlyPayment: product?.monthly_payment ? Number(product.monthly_payment) : 0,
  paymentAmount: product?.payments_sum ? Number(product.payments_sum) : 0,
  creditNumber: product?.credit_contract_no ? `${product?.credit_contract_no}` : '',
  isHasOfferDifference: _.get(product, 'has_offer_difference', false),

  productCode: _.get(product, 'product_code', ''),
  bankIdentification: _.get(product, 'external_issue_id', ''),

  actions: _.get(product, 'actions', []),
  isAvailRefund: _.get(product, 'avail_refund', false),
  isAvailSendDocuments: _.get(product, 'avail_send_documents', false),

  documentsDownload: _.map(_.get(product, 'printable_categories', []), d => ({
    name: d?.label,
    url: d?.url,
    id: d.id || uuid(),
    isMain: !!d?.main
  })),
  documentsUpload: _.flow(
    l => _.map(l, bundle => [..._.map(bundle?.scans, scan => ({
      id: scan?.id ?? scan?._id,
      type: _.get(scan, 'common_code', ''),
      name: _.get(scan, 'name', ''),
      fileName: scan?.url ? scan?.url.split('/').pop() : '',
      fileStatus: _.get(scan, 'status', ''),
      fileStatusText: _.get(scan, 'status_text', ''),
      fileUrl: _.get(scan, 'url', ''),
      isRequired: _.get(scan, 'need_upload', false),
      description: _.get(scan, 'description', ''),
      insurance: product?.insurance_offer ? {
        name: _.get(product, 'insurance_offer.name', ''),
        price: product?.insurance_offer.amount ? Number(product?.insurance_offer.amount) : 0
      } : null,
      fileSize: _.get(scan, 'file_size', 0)
    }))]),
    l => _.flatten(l)
  )(_.get(product, 'bundles', [])),

  packageId: _.get(product, 'poscans_package.id', null),
  qrCode: _.get(product, 'poscans_package.qr_code', ''),
  traceId: _.get(product, 'poscans_package.trace_id', ''),

  logLink: _.get(product, 'pl_admin_url', ''),
  creditLink: _.get(product, 'pl_admin_credit_url', '')
})

export const PRODUCT_CONFIRMED_STATES = 'confirmed' // Кредит выдан
export const PRODUCT_CONFIRM_WAITING_STATES = 'confirm_waiting' // Ожидание выдачи
export const PRODUCT_SES_CODE_WAITING_STATES = 'ses_code_waiting' // Ожидание ввода кода ПЭП
export const PRODUCT_SELECTED_STATES = 'selected' // Выбрано
export const PRODUCT_SELECT_WAIT_STATES = 'select_waiting' // Ожидание выбора
export const PRODUCT_PRE_POSITIVE_DECISION_STATES = 'pre_positive' // Перед отправкой документов
export const PRODUCT_POSITIVE_DECISION_STATES = 'positive_decision' // Одобрено
export const PRODUCT_CANCEL_STATES = 'refused' // Отменено
export const PRODUCT_REJECT_STATES = [
  // Не отправлялся
  'not_sent',
  // Отказано
  'negative_decision'
]
export const PRODUCT_ERRORS_STATES = 'failed' // Ошибка
export const PRODUCT_WAITING_REGISTER_STATES = [
  // Новая
  'new',
  // Ожидание регистрации
  'register_waiting'
]

export const CARD_REJECT_STATE = 'card_negative_decision' // В карте отказано
// Состояния карт, при которых отсутствуют данные о самом предложении банка. Нужно отображать в простом виде
export const CARD_SIMPLE_STATES = [
  'card_refused',
  'card_failed',
  'card_register_waiting',
  'card_registred',
  CARD_REJECT_STATE
]
// Все состояния кредитных карт
export const CARD_STATES = [
  'card_confirmed',
  'card_confirm_waiting',
  'card_select_waiting',
  'card_selected',
  'card_positive_decision',
  'card_ses_code_waiting',
  ...CARD_SIMPLE_STATES
]

export const PRODUCT_STATES = [
  PRODUCT_CONFIRMED_STATES,
  PRODUCT_CONFIRM_WAITING_STATES,
  PRODUCT_SES_CODE_WAITING_STATES,
  PRODUCT_SELECTED_STATES,
  PRODUCT_SELECT_WAIT_STATES,
  PRODUCT_PRE_POSITIVE_DECISION_STATES,
  PRODUCT_POSITIVE_DECISION_STATES,
  PRODUCT_CANCEL_STATES,
  PRODUCT_ERRORS_STATES,
  ...PRODUCT_REJECT_STATES,
  ...PRODUCT_WAITING_REGISTER_STATES
]

export const REFUND_INIT_STATE = 'init' // Возврат в начальном состоянии
export const REFUND_REJECTED_STATE = 'rejected' // Возврат отклонен
export const REFUND_REVIEWING_STATE = 'reviewing' // Возврат на рассмотрении
export const REFUND_APPROVED_STATE = 'approved' // Возврат принят

export const questionaryDashboardMap = ({ questionary }) => ({
  fullName: _.get(questionary, 'client.fio', ''),
  pointName: _.get(questionary, 'questionary.point_name', ''),
  pointId: _.get(questionary, 'questionary.point_id', ''),
  operatorName: _.get(questionary, 'questionary.operator_name', ''),
  operatorEmail: _.get(questionary, 'questionary.operator_email', ''),
  ownerEmail: _.get(questionary, 'questionary.owner_email', ''),
  ownerId: _.get(questionary, 'questionary.owner_id', ''),
  ownerFullname: _.get(questionary, 'questionary.owner_fullname', ''),
  retailName: _.get(questionary, 'questionary.retail_name', ''),
  state: _.get(questionary, 'questionary.state', ''),
  stateText: _.get(questionary, 'questionary.state_text', ''),
  createdDate: questionary?.questionary?.created_at ? moment(questionary?.questionary?.created_at, DATE_FORMATS).toDate() : null,

  payment: Number(_.get(questionary, 'questionary.goods_cost', 0)),
  initialPayment: Number(_.get(questionary, 'questionary.initial_payment', 0)),
  term: Number(_.get(questionary, 'questionary.term', 0)),

  isCanReject: _.get(questionary, 'questionary.can_reject', false),
  isCanComplete: _.get(questionary, 'questionary.can_complete', false),
  isNotCompletedByCallCenter: _.get(questionary, 'questionary.not_completed_by_call_center', false),
  isAvailDiscount: _.get(questionary, 'questionary.avail_discount', false),

  pdAgreement: {
    packageId: _.get(questionary, 'pd_agreement.package_id', null),
    scanId: _.get(questionary, 'pd_agreement.scan_id', null),
    url: _.get(questionary, 'pd_agreement.url', ''),
    uploadDate: questionary?.pd_agreement?.uploaded_at ? moment(questionary?.pd_agreement?.uploaded_at, DATE_FORMATS).toDate() : null
  },

  orders: _.map(_.get(questionary, 'questionary.goods'), order => {
    const resolve = goodsMap({ goods: order })
    return {
      ...resolve,
      id: resolve.id || uuid()
    }
  }),

  products: _.map(_.get(questionary, 'offers', []), product => productMap({ product })),

  pickupAdditionalServices: _.map(_.get(questionary, 'questionary.pickup_additional_services', []), service => ({
    ...serviceMap({ service }),
    conditionsUrl: _.get(service, 'conditions_url', '')
  })),

  isClonable: _.get(questionary, 'questionary.clonable', false),

  refund: questionary.refund ? refundMap(questionary.refund) : {}
})
export const postQuestionaryMap = ({ questionary }) => ({
  step: isSafe(questionary?.step) ? currentStep(questionary?.step) : null,
  id: questionary.id,
  options: {
    callCenter: {
      isPhoneConfirmationRequired: _.get(questionary, 'options.call_center.phone_confirmation_required', false),
      isScansRequired: _.get(questionary, 'options.call_center.scans_required', false),
      isTransfer: _.get(questionary, 'options.call_center.transfer', false)
    }
  }
})
export const d2ItemMapApi = ({ item }) => ({
  id: _.get(item, 'id', ''),
  manufacturer: _.get(item, 'manufacturer', ''),
  name: _.get(item, 'name', ''),
  price: _.get(item, 'price', 0),
  serial_number: _.get(item, 'serialNumber', '')
})
